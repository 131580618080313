<template>
  <div class="brand_container" :style="`background: ${info.bj_color}`">
    <!-- <div v-if="info.adv_img" class="img" @click="handleJump(info.adv_url)">
      <img :src="info.adv_img" alt="" />
    </div> -->
    <div class="content">
      <!-- <div class="total_box flex_row" :class="showMore ? 'nomore' : ''">
        <p>品牌分类：</p>
        <div class="flex_row nav_wrap">
          <div v-for="item in toplist" :key="item.id" class="nav_item" :class="[item.sel == 101 ? 'color_theme' : '']" @click="goto(item.id)">{{ item.title }}</div>
        </div>
        <div class="more flex_c_c" v-if="fold" @click="showMore = !showMore">
          <div v-if="showMore">
            更多
            <i class="el-icon-arrow-down"></i>
          </div>
          <div v-else>
            收起
            <i class="el-icon-arrow-up"></i>
          </div>
        </div>
      </div> -->
      <div class="item_wrap">
        <div v-for="item in list" :key="item.id" class="box">
          <div class="item">
            <img class="bigimg" style="height: 90px" :src="item.image" alt="" />
            <!-- <p class="c3 f16 title">{{ item.title }}</p> -->
            <div class="goods-content">
              <div v-for="sub in item.goods.slice(0, 3)" :key="sub.id" class="flex_d_c_c goods_box">
                <img style="width: 100px; height: 100px" :src="sub.logo" alt="" />
                <p class="red">￥{{ sub.price }}</p>
                <p class="c3 shenglue_1 bold">{{ sub.title }}</p>
              </div>
            </div>
            <div class="bottom">
              <div class="flex_row_aic c_p" @click="handleMore(item)">
                共 <span class="color_theme">{{ item.goods.length }} </span>个商品
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <pagination :total="total" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />
    </div>
  </div>
</template>

<script>
import { handleJump, getZoneInfo } from "@/utils";
import pagination from "@/components/pagination.vue";
// import { getTextWidth, DeepClone } from "@/utils/tool";
import { DeepClone } from "@/utils/tool";
export default {
  components: {
    pagination,
  },
  data() {
    return {
      toplist: [],
      list: [],
      info: {},
      handleJump,
      showMore: false,
      fold: false,
      total: 0,
      page: 1,
      page_size: 10,
    };
  },
  created() {
    this.getData("");
    getZoneInfo(this.$route.query.value).then((res) => {
      this.info = res;
    });
  },
  methods: {
    getData(brand_id) {
      this.$api("zone.getBrand", { brand_id: brand_id, page: this.page, page_size: this.page_size }).then((res) => {
        // let num = 0;
        // res.data.forEach((e) => {
        //   e.goods = e.goods.slice(0, 3);
        //   num += getTextWidth(e.title) + 24;
        // });
        // if (num > 1019) {
        //   this.showMore = true;
        //   this.fold = true;
        // } else {
        //   this.showMore = false;
        //   this.fold = false;
        // }
        // if (!brand_id) {
        //   let arr = DeepClone(res.data);
        //   arr.unshift({ title: "全部", id: 0, sel: 101 });
        //   this.toplist = arr;
        // }
        this.list = res.data.list;
        this.total = res.data.count;
      });
    },
    goto(id) {
      let list = DeepClone(this.toplist);
      list.forEach((e) => {
        if (e.id == id) e.sel = 101;
        else e.sel = 100;
      });
      this.toplist = list;
      this.getData(id);
    },
    handleMore(item) {
      this.$store.commit("CHANGE_PAGENAME", []);
      this.$store.commit("CHANGE_TAB", 1);
      this.$router.push({
        name: "AllGoods",
        params: {
          show: false,
          brand_id: item.id,
          pname: item.title,
        },
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData("");
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getData("");
    },
  },
};
</script>

<style lang="less" scoped>
.brand_container {
  background-size: cover;
  padding-bottom: 20px;
}
.img {
  width: 100%;
  height: 375px;
}
.content {
  padding-bottom: 20px;
  .total_box {
    overflow: hidden;
    margin-top: 20px;
    width: 1200px;
    background: #ffffff;
    border-radius: 4px;
    // overflow: hidden;
    color: #333;
    line-height: 58px;
    padding: 0 20px;
    box-sizing: border-box;
    p {
      margin-right: 10px;
    }
    .nav_wrap {
      flex: 1;
      flex-wrap: wrap;
    }
    .nav_item {
      margin-right: 24px;
      cursor: pointer;
    }
    .more {
      width: 59px;
      height: 27px;
      border: 1px solid #999999;
      border-radius: 2px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      margin-top: 15px;
      cursor: pointer;
    }
  }
  .nomore {
    height: 58px;
  }
}
.item_wrap {
  display: flex;
  flex-wrap: wrap;
  .bigimg {
    // width: 380px;
    // height: 120px;
    // border-radius: 12px 12px 0 0;
    width: 98px;
    height: 98px;
    // border-radius: 50%;
  }
  .box {
    margin-right: 30px;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  .item {
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    margin-top: 20px;
    width: 380px;
    flex-shrink: 0;

    .title {
      margin: 15px 10px;
      font-weight: bold;
    }
    .red {
      color: @priceRed;
      font-weight: bold;
      margin-top: 10px;
    }
    .goods-content {
      display: flex;
    }
    .goods_box {
      padding: 0 10px;
      margin-bottom: 10px;
      line-height: 1.5;
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      padding: 10px;
      border-top: 1px solid #eee;
    }
  }
}
</style>
